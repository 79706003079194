<template>
  <view>
    按天
    <view>订单数</view>
    <view>订单金额</view>
    <view>退款数</view>
    <view>退款金额</view>
    <view>新增用户数</view>
    <view>新用户购买</view>
    <view>用户续费</view>
    按月
    <view>订单数</view>
    <view>订单金额</view>
    <view>退款数</view>
    <view>退款金额</view>
    <view>新增用户数</view>
    <view>新用户购买</view>
    <view>用户续费</view>
    <view>提现</view>
    <view>用户推荐列表</view>
    
  </view>
</template>